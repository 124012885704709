<template>
  <div class="registry d-flex justify-center">
    <div class="card-wrap w-full">
      <pageTitle icon="icon-registry" text="registry"></pageTitle>

      <v-form ref="form" lazy-validation>
        <inputBlock class="mb-2" title="referer" placeholder="手機號碼" :inputText.sync="referer" column :readonly="!!$route.query.referer" :rules="[required, ...phoneRules]"></inputBlock>
        <inputBlock class="mb-2" title="phone" :inputText.sync="phone" column :rules="[required, ...phoneRules]"></inputBlock>
        <inputBlock class="mb-2" title="validateCode" :inputText.sync="validateCode" column isSend @send="sendValidateCode()"></inputBlock>
        <inputBlock class="mb-2" title="password" :inputText.sync="password" type="password" column :rules="[required]"></inputBlock>
        <inputBlock class="mb-2" title="name" :inputText.sync="name" column :rules="[required]"></inputBlock>
        <inputBlock class="mb-2" title="idNumber" :inputText.sync="idNumber" column :rules="[required, ...idRules]"></inputBlock>
        <inputBlock class="mb-2" title="gender" mode="select" :selectItems="[{name: $t('male'), value: 1}, {name: $t('female'), value: 2}]" :inputText.sync="gender" column :rules="[required]"></inputBlock>
        <inputBlock class="mb-2" title="birth" mode="date" :inputText.sync="birth" column :rules="[required]"></inputBlock>
        <inputBlock class="mb-2" title="BSCaddress" :inputText.sync="BSCaddress" column :rules="[required, ...accountRules]"></inputBlock>
        <inputBlock class="mb-2" title="email" :inputText.sync="email" column :rules="[required]"></inputBlock>
        <inputBlock class="mb-5" title="residentialAddress" :inputText.sync="residentialAddress" column :rules="[required]"></inputBlock>
      </v-form>

      <btn buttonText="confirm" width="230" isCenter @clickBtn="register()"></btn>
    </div>
  </div>
</template>
<script>
import inputBlock from '@/components/inputBlock.vue'
import pageTitle from '@/components/pageTitle.vue'
import btn from '@/components/btn.vue'
import base from '@/mixin/base'
export default {
  name: "Registry",
  mixins: [base],
  data(){
    return {
      referer: '',
      phone: '',
      validateCode: '',
      password: '',
      name: '',
      idNumber: '',
      gender: 1,
      birth: '',
      BSCaddress: '',
      email: '',
      residentialAddress: '',
    }
  },
  components:{
    inputBlock,
    pageTitle,
    btn
  },
  methods:{
    async sendValidateCode(){
      let re = /09[\d]{8}/g;
      if (this.phone.match(re)){
        let data = {
          phone_number: this.phone.slice(1),
          phone_country_code: this.phone[0],
          status: 0
        }
        let result = await this.$store.dispatch('getValidateCode', data)
        console.log('result', result)
        if (result.status === 731){
          this.$toasted.error(this.$t('sendSuccess'))
        }else if (result.status === 734){
          this.$toasted.error(this.$t('codeNoExpired'))
        }else if (result.status === 676){
          this.$toasted.error(this.$t('accountHasUsed'))
        }else{
          this.$toasted.error(this.$t('sendFail'))
        }
      }else{
        this.$toasted.error(this.$t('pleaseEnterCorrectAccount'))
      }
    },
    async register(){
      if (this.$refs.form.validate()){
        let data = {
          password: this.password,
          phone_number: this.phone.slice(1),
          phone_country_code: this.phone[0],
          phone_verify_code: this.validateCode,
          address: this.BSCaddress,
          name: this.name,
          id_card: this.idNumber,
          gender: this.gender === 1 ? 'male' : 'female',
          birthday: this.birth.split('-').join(''),
          email: this.email,
          live_address: this.residentialAddress,
          referrer: this.referer.slice(1),
          referrer_country_code: this.referer[0]
        }
        let result = await this.$store.dispatch('register', data)
        console.log('result', result)
        if (result.status === 671){
          this.$toasted.error(this.$t('registerSuccess'))
          this.$router.push({name: "Home", params: {lang: this.$store.state.locale}})
        }else if (result.status === 682){
          this.$toasted.error(this.$t('addressHasUsed'))
        }else if (result.status === 732){
          this.$toasted.error(this.$t('codeHasExpired'))
        }else if (result.status === 733){
          this.$toasted.error(this.$t('codeWrong'))
        }else{
          this.$toasted.error(this.$t('registerFail'))
        }
      }
    }
  },
  mounted(){
    this.referer = this.$route.query.referer ? this.$route.query.referer : ''
  }
}
</script>